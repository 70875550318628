<template>
  <div>
    <loading-spinner
      v-if="!isImageLoaded"
      v-bind="{size: 6, margin: 30}"
    >
    </loading-spinner>
    <img ref="imageElement" v-bind:class="[{'ease-in': easeIn}, imageClass]" v-bind:src="src" v-if="isImageLoaded" v-bind:style="styles">
  </div>
</template>

<script>
import LoadingSpinner from './LoadingSpinner'

export default {
  name: 'image-on-load',
  props: {
    'imageClass': String,
    'src': String,
    'maxWidth': {
      type: String,
      default: 'auto'
    },
    'maxHeight': {
      type: String,
      default: 'auto'
    }
  },
  data: function () {
    return {
      isImageLoaded: false,
      easeIn: false,
      styles: {
        maxHeight: this.maxHeight || 'auto',
        maxWidth: this.maxWidth || 'auto',
      },
      imageWorker: new Image
    }
  },
  mounted: function () {
    this.imageWorker.onload = () => {
      setTimeout(() => {
        this.isImageLoaded = true
      }, 200)
      setTimeout(() => {
        this.easeIn = true;
      }, 300)
    }
    this.imageWorker.src = this.src;

    if (this.imageWorker.complete) {
      this.isImageLoaded = true
      setTimeout(() => {
        this.easeIn = true;
      })
    }
  },
  components: {
    LoadingSpinner
  }
}
</script>

<style scoped lang="scss">
img {
  opacity: 0;

  &.ease-in {
    transition: opacity ease 1.5s;
    opacity: 1;
  }
}
</style>
