export function createCalculation (calculationData, products) {
  products = products || []
  return {
    floors (height) {
      return Math.ceil(height / 2)
    },
    fields (fieldLength, length) {
      return Math.round(length / calculationData.Bays[fieldLength])
    },
    findArticle (articleNumber) {
      return products.find((article) => articleNumber === article.article_number)
    },
    BaseJack (fieldLength, length) {
      const articleNo = calculationData.BaseJack.BASIC.ArtNo
      let article = this.findArticle(articleNo)

      article.quantity = (this.fields(fieldLength, length) + 1) * 2
      return article
    },
    AssemblyFrame (system, fieldLength, length, height) {
      const articleNo = calculationData.AssemblyFrame[system].ArtNo
      let article = this.findArticle(articleNo)

      article.quantity = (this.fields(fieldLength, length) + 1) * this.floors(height)
      return article
    },
    Decks (fieldLength, length, material, system, height, stairCount) {
      const articleNo = calculationData.Decks[fieldLength][material].ArtNo
      let article = this.findArticle(articleNo)
      const floor = this.floors(height)
      const factor = ['ALFIX100S', 'UNIFIX100S'].indexOf(system) > -1 ? 3 : (['SOLIDWOOD', 'STEEL'].indexOf(material) > -1 ? 2 : 1)

      article.quantity = factor * this.fields(fieldLength, length) * floor - (stairCount * floor * Math.min(factor, 2))
      return article
    },
    EndToeboard (system, height) {
      const articleNo = calculationData.EndToeboard[system].ArtNo
      let article = this.findArticle(articleNo)

      article.quantity = this.floors(height) * 2
      return article
    },
    EndGuardrail (system, height) {
      const articleNo = calculationData.EndGuardrail[system].ArtNo
      let article = this.findArticle(articleNo)

      article.quantity = (height < 3) ? 0 : parseInt(height, 10) - 2
      return article
    },
    Toeboard (fieldLength, length, height) {
      const articleNo = calculationData.Toeboard[fieldLength].ArtNo
      let article = this.findArticle(articleNo)

      article.quantity = this.fields(fieldLength, length) * this.floors(height)
      return article
    },
    GuardrailPost (system, fieldLength, length) {
      const articleNo = calculationData.GuardrailPost[system].ArtNo
      let article = this.findArticle(articleNo)

      article.quantity = this.fields(fieldLength, length) - 1
      return article
    },
    EndGuardrailFrame (system) {
      const articleNo = calculationData.EndGuardrailFrame[system].ArtNo
      let article = this.findArticle(articleNo)

      article.quantity = 2
      return article
    },
    HatchPlatform (fieldLength, height, stairCount) {
      const articleNo = calculationData.HatchPlatform[fieldLength].ArtNo
      let article = this.findArticle(articleNo)

      article.quantity = stairCount * this.floors(height)
      return article
    },
    RearGuardrail (fieldLength, length, height) {
      const articleNo = calculationData.RearGuardrail[fieldLength].ArtNo
      let article = this.findArticle(articleNo)

      article.quantity = this.fields(fieldLength, length) * (this.floors(height) * 2 + 1)
      return article
    },
    HorizontalStrut (fieldLength, length) {
      const articleNo = calculationData.HorizontalStrut[fieldLength].ArtNo
      let article = this.findArticle(articleNo)

      article.quantity = Math.ceil(this.fields(fieldLength, length) / 5)
      return article
    },

    DiagonalBrace (fieldLength, length, height) {
      const articleNo = calculationData.DiagonalBrace[fieldLength].ArtNo
      let article = this.findArticle(articleNo)

      article.quantity = Math.ceil(this.fields(fieldLength, length) / 5) * this.floors(height)
      return article
    },

    Normalkupplung (fieldLength, length, height) {
      const sectionLength = calculationData.Bays[fieldLength]
      const articleNo = calculationData.Normalkupplung['BASIC'].ArtNo
      let article = this.findArticle(articleNo)

      article.quantity = Math.ceil((length / (sectionLength * 2)) * (height / 4)) + 2
      return article
    },

    Schnellanker (fieldLength, length, height) {
      const sectionLength = calculationData.Bays[fieldLength]
      const articleNo = calculationData.Schnellanker['BASIC'].ArtNo
      let article = this.findArticle(articleNo)

      article.quantity = Math.ceil((length / (sectionLength * 2)) * (height / 4)) + 2
      return article
    },

    NylonUniversalduebel (fieldLength, length, height) {
      const sectionLength = calculationData.Bays[fieldLength]
      const articleNo = calculationData.NylonUniversalduebel['BASIC'].ArtNo
      let article = this.findArticle(articleNo)

      article.quantity = Math.ceil((length / (sectionLength * 2)) * (height / 4)) + 2
      return article
    },

    Abdeckkappe (fieldLength, length, height) {
      const sectionLength = calculationData.Bays[fieldLength]
      const articleNo = calculationData.Abdeckkappe['BASIC'].ArtNo
      let article = this.findArticle(articleNo)

      article.quantity = Math.ceil((length / (sectionLength * 2)) * (height / 4)) + 2
      return article
    },

    Ringschraube (fieldLength, length, height) {
      const sectionLength = calculationData.Bays[fieldLength]
      const articleNo = calculationData.Ringschraube['BASIC'].ArtNo
      let article = this.findArticle(articleNo)

      article.quantity = Math.ceil((length / (sectionLength * 2)) * (height / 4)) + 2
      return article
    },

    Abstandsrohr (fieldLength, length, height) {
      const sectionLength = calculationData.Bays[fieldLength]
      const articleNo = calculationData.Abstandsrohr['BASIC'].ArtNo
      let article = this.findArticle(articleNo)

      article.quantity = parseInt(Math.ceil((length / (sectionLength * 2)) * (height / 4)), 10) + 2
      return article
    },

    UntereDiagonalbefestigung (fieldLength, length) {
      const articleNo = calculationData.UntereDiagonalbefestigung['BASIC'].ArtNo
      let article = this.findArticle(articleNo)

      article.quantity = Math.ceil(parseFloat(this.fields(fieldLength, length)) / 5)
      return article
    }
  }
}
