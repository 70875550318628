<template>
  <section>
    <h3>{{ $t('title') }}</h3>
    <p>
      <i>{{ $t('subtitle') }}</i>
    </p>

    <ul>
      <li
        v-on:click="isValid(key) && setActiveWorkingHeight(key)"
        v-for="(key, index) in heightKeys"
        v-bind:key="key"
        v-bind:class="{ active: (activeWorkingHeight === key) || (!activeWorkingHeight && index === 0), disabled: !isValid(key) }"
      >
        {{ $t('height', {value: $n(heights[key].height, 'measurement')}) }}
      </li>
    </ul>
    <footer-next v-bind:label="translations.next" v-on:next="setActiveWorkingHeight(activeWorkingHeight || heightKeys[0], true)"></footer-next>
  </section>
</template>

<script>
import FooterNext from '../../../common/src/components/FooterNext'

export default {
  name: 'step-working-heights',
  props: ['translations', 'activeMeasurement', 'activeBuildVariant', 'activeArea', 'activeWorkingHeight', 'calculationData'],
  data: function () {
    const data = {...this.calculationData.mobileScaffolds[this.calculationData.scaffoldSizes[this.activeMeasurement]]}
    const heights = data.models

    return {
      heights: heights,
      heightKeys: Object.keys(heights),
      variant: data.availableVariants[this.activeBuildVariant]
    }
  },
  methods: {
    setActiveWorkingHeight: function (key, emitNextStep) {
      if (key !== this.activeWorkingHeight) {
        this.$emit('activeWorkingHeightChanged', key)
      }
      if (emitNextStep) {
        this.$emit('nextStep')
      }
    },
    isValid: function (key) {
      return this.heights[key][this.activeArea].valid.indexOf(this.variant) > -1
    }
  },
  components: {
    FooterNext
  },
  i18n: {
    messages: {
      de: {
        height: '{value} m',
        title: 'Arbeitshöhe auswählen',
        subtitle: 'Arbeitshöhe entspricht Standhöhe +2m'
      },
      en: {
        height: '{value} m',
        title: 'Select working height',
        subtitle: 'Working height equals standing height +2m'
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
p {
  padding: 5px;
}

ul {
  list-style-type: none;
  padding: 0;
  width: 60%;
  margin: 0 auto;
}

li:hover {
  color: #0065B7;
}

li {
  display: block;
  margin: 0 10px;
  padding: 8px;
  color: #101314;
  border: 2px solid transparent;
  cursor: pointer;
}

li.active {
  font-weight: bold;
  border-color: #0065B7;

  &:hover, &:active, &:focus {
    color: #101314;
  }
}

li.disabled {
  color: #c4d1dd;
}

li.disabled:hover {
  color: #c4d1dd;
}
</style>
