<template>
  <div id="weather-proof-roof-app">
    <div class="pure-g wrapper">
      <div class="pure-u-11-12 pure-u-md-5-6 pure-u-lg-17-24">
        <div class="pure-g wrapper">
          <div class="pure-u-2-3 pure-u-md-1-3">
            <step-indicator v-on:stepChanged="handleStepChange" v-bind:steps="steps" v-bind:activeStep="activeStep"></step-indicator>
          </div>
        </div>
        <component
          v-if="calculationData"
          v-bind:is="currentView"
          v-bind="currentProperties"
          @dataChanged="handleDataChanged"
          @nextStep="nextStep"
        >
        </component>

        <loading-spinner v-if="!calculationData" v-bind="{size: 6, margin: 60}"></loading-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from '../../common/src/components/LoadingSpinner'
import StepIndicator from '../../common/src/components/StepIndicator'
import { getWeatherProofAlfixCalculationData, getWeatherProofUnifixCalculationData } from '../../common/src/index'
import { COMPONENTS, STEPS } from './data/steps'

import { numberFormats } from '../../common/src'

import translations from './data/translations'

import Vue from 'vue'
import VueI18n from 'vue-i18n'

import VueJSModal from 'vue-js-modal'

Vue.config.productionTip = false

Vue.use(VueI18n)
Vue.use(VueJSModal, { dynamic: true, injectModalsContainer: true })

const userLocale = window.navigator.language || window.navigator.userLanguage || window.navigator.userLanguage || window.navigator.languages[0] || 'en'
const locationLocale = window.location.href.indexOf('?lang=') > -1 ? window.location.href.split('?lang=').pop() : null

const i18n = new VueI18n({
  locale: locationLocale ? locationLocale.substring(0, 2) : userLocale.substring(0, 2),
  fallbackLocale: 'en',
  numberFormats
})

const defaultStep = 0
let alfixData = {}
let unifixData = {}

export default {
  name: 'weather-proof-roof-app',
  data: function () {
    return {
      system: 'alfix',
      span: null,
      length: null,
      calculationData: null,
      activeStep: defaultStep,
      steps: STEPS.map((step) => step.alias),
      currentView: STEPS[defaultStep].component
    }
  },
  mounted: function () {
    Promise.all([getWeatherProofAlfixCalculationData(), getWeatherProofUnifixCalculationData()]).then(([calcDataAlfix, calcDataUnifix]) => {
      alfixData = calcDataAlfix
      unifixData = calcDataUnifix

      // Default to alfix
      this.span = calcDataAlfix.spans[0]
      this.length = calcDataAlfix.rooflengths[0]
      this.calculationData = {
        alfix: calcDataAlfix,
        unifix: calcDataUnifix
      }
    })
  },
  methods: {
    handleDataChanged: function (data) {
      this.length = data.length
      this.span = data.span
      this.system = data.system
    },
    handleStepChange: function (step) {
      this.activeStep = step
      this.currentView = STEPS[step].component
    },
    nextStep: function () {
      this.handleStepChange(this.activeStep + 1)
    }
  },
  computed: {
    currentProperties: function () {
      if (this.currentView.name === 'step-calculation-results') {
        return { translations: translations[this.$i18n.locale], system: this.system, length: this.length, span: this.span, calculationData: this.calculationData }
      } else {
        return { translations: translations[this.$i18n.locale], system: this.system, length: this.length, span: this.span, calculationData: this.calculationData }
      }
    }
  },
  components: {
    StepIndicator: StepIndicator,
    LoadingSpinner: LoadingSpinner,
    ...COMPONENTS
  },
  i18n
}
</script>

<style lang="scss">
#weather-proof-roof-app {
  color: #101314;
  font-size: 1.2rem;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 60px;

  div.wrapper {
    justify-content: center;
  }
}

.loading-wrapper {
  width: 300px;
  height: 300px;
  position: relative;
  overflow: hidden;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
</style>
