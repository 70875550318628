import axios from 'axios'

let calculationData = null
const dataUrl = (window._alfix_data_host ? window._alfix_data_host : 'https://www.alfix-systems.com/shop/app-upload') + '/weather-proof-alfix-articles.json'

export function getWeatherProofAlfixCalculationData () {
  if (calculationData) {
    return Promise.resolve(calculationData)
  }

  return axios.get(dataUrl).then(function (response) {
    calculationData = response.data
    calculationData.rooflengths = calculationData.rooflengths.filter(function (length) { return !!length }).sort(function (a, b) { return a - b })
    calculationData.spans = calculationData.spans.filter(function (span) { return !!span }).sort(function (a, b) { return a - b })
    return calculationData
  })
}
