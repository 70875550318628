import axios from 'axios'

let products = []
const dataUrl = (window._alfix_data_host ? window._alfix_data_host : 'https://www.alfix-systems.com/shop/app-upload') + '/products.json'

export function getProducts () {
  if (products.length) {
    return Promise.resolve(products)
  }

  const removeDuplicates = function (arr) {
    const uniqueArray = []

    arr.forEach(function (elem, _index) {
      if (uniqueArray.findIndex(function (existingElem) {
        return existingElem.article_number === elem.article_number
      }) === -1) {
        uniqueArray.push(elem)
      }
    })

    return uniqueArray
  }

  return axios.get(dataUrl)
    .then(function (response) {
      products = removeDuplicates(response.data)
      return products
    })
}
