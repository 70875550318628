import axios from 'axios'

let calculationData = null
const dataUrl = (window._alfix_data_host ? window._alfix_data_host : 'https://www.alfix-systems.com/shop/app-upload') + '/mobile-scaffold-articles.json'

export function getMobileCalculationData () {
  if (calculationData) {
    return Promise.resolve(calculationData)
  }

  return axios.get(dataUrl)
    .then(function (response) {
      calculationData = response.data
      return calculationData
    })
}
