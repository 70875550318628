<template>
  <div>
    <button class="pure-button pure-button-primary" v-bind:style="styles" type="button" v-on:click="next">{{ label }}</button>
  </div>
</template>

<script>
export default {
  name: 'footer-next',
  props: {
    'label': String,
    'btnBackgroundColor': {
      type: String,
      default: '#0065B7'
    },
    'btnColor': {
      type: String,
      default: 'white'
    }
  },
  computed: {
    styles: function () {
      return {
        'color': this.btnColor,
        'background-color': this.btnBackgroundColor
      }
    }
  },
  methods: {
    next: function () {
      this.$emit('next')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
div {
  padding: 20px;
}
</style>
