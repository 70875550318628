
<template>
  <div>
    <h3>{{ $t('title') }}</h3>
    <swiper :options="swiperOption" class="swiper-box" ref="mySwiper">
      <!-- slides -->
      <swiper-slide v-for="(variant, index) in variants" v-bind:key="index" class="swiper-item">
        <image-on-load v-bind="{ src: images[`mobilescaffold_${variant.toLowerCase()}`], maxHeight: '50vh', maxWidth: '80vw' }"></image-on-load>
        <br>
        {{ translations[variant] }}
      </swiper-slide>

      <!-- Optional controls -->
      <div class="swiper-pagination" slot="pagination"></div>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </swiper>
    <footer-next v-bind:label="translations.next" v-on:next="setVariant(true)"></footer-next>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import FooterNext from '../../../common/src/components/FooterNext'
import ImageOnLoad from '../../../common/src/components/ImageOnLoad'

import images from '../assets/variants/*.jpg'

import 'swiper/css/swiper.css'

export default {
  name: 'step-variants',
  props: ['translations', 'activeBuildVariant', 'activeMeasurement', 'calculationData'],
  data() {
    return {
      swiperOption: {
        autoHeight: false,
        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: false,
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        type: 'fraction',
        slidesPerView: 1,
        activeIndex: this.activeBuildVariant,
        roundLengths: true,
        loop: false
      },
      images,
      variants: this.calculationData.mobileScaffolds[this.calculationData.scaffoldSizes[this.activeMeasurement]].availableVariants
    }
  },
  methods: {
    setVariant: function (emitNextStep) {
      const activeIndex = this.$refs.mySwiper.$swiper.activeIndex
      if (activeIndex !== this.activeBuildVariant) {
        this.$emit('activeBuildVariantChanged', activeIndex)
      }
      if (emitNextStep) {
        this.$emit('nextStep')
      }
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      this.$refs.mySwiper.$swiper.slideTo(this.activeBuildVariant)
    })
  },
  components: {
    Swiper, SwiperSlide, ImageOnLoad, FooterNext
  },
  i18n: {
    messages: {
      en: {
        'title': 'Select structural design'
      },
      de: {
        'title': 'Bauvarianten auswählen'
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.swiper-box {
  max-width: 100%;
  margin: 0 auto;

  .swiper-item {
    text-align: center;
    padding-bottom: 20px;
    background-color: #fff;
    color: #101314;

    .image-box {
      max-height: 50vh;
      max-width: 80vw;
    }
  }

  .swiper-pagination {
    position: relative;
  }
}
</style>
