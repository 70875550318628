import axios from 'axios'

let calculationData = null
const dataUrl = (window._alfix_data_host ? window._alfix_data_host : 'https://www.alfix-systems.com/shop/app-upload') + '/alfix-articles.json'

export function getAlfixCalculationData () {
  if (calculationData) {
    return Promise.resolve(calculationData)
  }

  return axios.get(dataUrl)
    .then(function (response) {
      calculationData = Object.assign({}, response.data, {
        'MaxLengths': {
          'BAY257': 498.58,
          'BAY307': 497.34
        },
        'Bays': {
          'BAY257': 2.57,
          'BAY307': 3.07
        }
      })

      let transformedDecks = {
        'BAY257': {},
        'BAY307': {}
      }

      Object.keys(calculationData.Decks).forEach(function (key) {
        const currentBay = key.indexOf('BAY257') > -1 ? 'BAY257' : 'BAY307'
        let material = key.substr(0, key.indexOf(currentBay))

        transformedDecks[currentBay][material] = {
          ArtNo: calculationData.Decks[key].ArtNo,
          AssemblyFrames: ['STEEL', 'SOLIDWOOD'].indexOf(material) > -1 ? ['ALFIX100S', 'ALFIX70S', 'ALFIX70A'] : ['ALFIX70S', 'ALFIX70A']
        }
      })
      calculationData.Decks = transformedDecks

      return calculationData
    })
}
