<template>
  <div>
    <h3>{{ $t('system') }}</h3>
    <section>
      <ul>
        <li
          v-on:click="setSystem(system)"
          v-for="(system) of ['alfix', 'unifix']"
          v-bind:key="system"
          v-bind:class="{ active: (activeSystem === system) }"
        >
          {{ $t(system) }}
        </li>
      </ul>
    </section>

    <h3>{{ $t('length') }}</h3>
    <section>
      <form class="pure-form">
        <select v-model="activeLength">
          <option v-bind:key="length" v-for="(length) of lengths" v-bind:value="length">{{ $n(length, 'measurement') }} m</option>
        </select>
      </form>
    </section>

    <h3>{{ $t('span') }}</h3>
    <section>
      <form class="pure-form">
        <select v-model="activeSpan">
          <option v-bind:key="span" v-for="(span) of spans" v-bind:value="span">{{ $n(span, 'measurement') }} m</option>
        </select>
      </form>
    </section>

    <footer-next v-bind:label="$t('calculate')" v-on:next="setData()"></footer-next>
  </div>
</template>

<script>
import FooterNext from '../../../common/src/components/FooterNext'
import OnHoldButton from '../../../common/src/components/OnHoldButton'

export default {
  name: 'step-calculation-form',
  props: {
    'system': String,
    'span': Number,
    'length': Number,
    'calculationData': Object
  },
  data: function () {
    return {
      activeSystem: this.system,
      activeSpan: this.span,
      activeLength: this.length
    }
  },
  computed: {
    lengths: function () {
      return this.calculationData[this.activeSystem].rooflengths
    },
    spans: function () {
      return this.calculationData[this.activeSystem].spans
    }
  },
  methods: {
    setSpan: function (span) {
      if (span === this.activeSpan) {
        return null
      }

      this.activeSpan = span
    },
    setLength: function (length) {
      if (length === this.activeLength) {
        return null
      }

      this.activeLength = length
    },
    setSystem: function (system) {
      if (system === this.activeSystem) {
        return null
      }

      let currentLengthIndex = this.calculationData[this.activeSystem].rooflengths.findIndex((length) => length === this.activeLength)
      let newLength = this.calculationData[system].rooflengths[0]
      if (currentLengthIndex > -1 && !!this.calculationData[system].rooflengths[currentLengthIndex]) {
        newLength = this.calculationData[system].rooflengths[currentLengthIndex]
      }

      this.activeLength = newLength
      this.activeSystem = system
    },
    setData: function () {
      this.$emit('dataChanged', {
        length: this.activeLength,
        span: this.activeSpan,
        system: this.activeSystem
      })
      this.$emit('nextStep')
    },
    roundToTwoDecimals: function (value) {
      return parseFloat(value.toFixed(2))
    }
  },
  i18n: {
    messages: {
      de: {
        'alfix': 'ALFIX',
        'unifix': 'UNIFIX',
        'calculate': 'Berechnen',
        'span': 'Spannweite',
        'length': 'Dachlänge',
        'system': 'Gerüstsystem'
      },
      en: {
        'alfix': 'ALFIX',
        'unifix': 'UNIFIX',
        'calculate': 'Calculate',
        'span': 'Span',
        'length': 'Roof length',
        'system': 'Scaffolding system'
      }
    }
  },
  components: {
    FooterNext,
    OnHoldButton
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
p {
  padding: 5px;
}

ul {
  list-style-type: none;
  padding: 0;
  width: 60%;
  margin: 0 auto;
}

@media screen and (max-width: 35.5em) {
  ul {
    width: 98%;
  }
}

li {
  display: block;
  margin: 0 10px;
  padding: 8px;
  border: 2px solid transparent;
  color: #101314; // dark gray
  cursor: pointer;
}

li:hover {
  color: #0065B7; // blue
}

li.active {
  font-weight: bold;
  border-color: #0065B7;

  &:hover, &:active, &:focus {
    color: #101314 // dark gray
  }
}

button {
  border: 1px solid transparent;
}

button.pure-button.number {
  min-width: 125px;
  background: #fff;
  cursor: default;
  color: #0065B7;
  border: 1px solid #0065B7;

  &:hover {
    background: #fff;
    box-shadow: 0 0 0 0 transparent;
  }

  &:active {
    background: #fff;
    box-shadow: 0;
  }
}

section {
  margin-bottom: 40px;

  &:last-of-type {
    margin-bottom: 20px;
  }
}

.pure-button-group {
  margin-bottom: 10px;

  &.margin-top {
    margin-top: 10px;
  }
}
</style>
