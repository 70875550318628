// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'core-js/stable'

import Vue from 'vue'
import App from './App'

import 'purecss/build/pure.css'
import 'purecss/build/grids-responsive.css'

new Vue(App).$mount('#app')
