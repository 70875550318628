import Area from '../components/Area'
import CalculationResults from '../components/CalculationResults'
import RampMeasurements from '../components/RampMeasurements'
import Variants from '../components/Variants'
import WorkingHeight from '../components/WorkingHeight'

export const STEPS = [{
  alias: 'RampMeasurements',
  component: RampMeasurements
}, {
  alias: 'Variants',
  component: Variants
}, {
  alias: 'Area',
  component: Area
}, {
  alias: 'WorkingHeight',
  component: WorkingHeight
}, {
  alias: 'CalculationResults',
  component: CalculationResults
}]

export const COMPONENTS = {
  'Area': Area,
  'CalculatingResults': CalculationResults,
  'RampMeasurements': RampMeasurements,
  'Variants': Variants,
  'WorkingHeight': WorkingHeight
}
