<template>
  <section>
    <h3>{{ $t('title') }}</h3>

    <ul>
      <li v-on:click="setActiveMeasurement(measurement)" v-for="(measurement) in measurements" v-bind:key="measurement" v-bind:class="{ active: activeMeasurement === measurement }">
        {{ translations[measurement] }}
      </li>
    </ul>
    <footer-next v-on:next="setActiveMeasurement(activeMeasurement, true)" v-bind:label="translations.next"></footer-next>
  </section>
</template>

<script>
import FooterNext from '../../../common/src/components/FooterNext'

export default {
  name: 'step-ramp-measurement',
  props: ['translations', 'activeMeasurement', 'calculationData'],
  data: function () {
    return {
      hoverState: false,
      measurements: Object.keys(this.calculationData.scaffoldSizes)
    }
  },
  methods: {
    setActiveMeasurement: function (measurement, emitNextStep) {
      if (measurement !== this.activeMeasurement) {
        this.$emit('activeMeasurementChanged', measurement)
      }
      if (emitNextStep) {
        this.$emit('nextStep')
      }
    }
  },
  components: {
    FooterNext
  },
  i18n: {
    messages: {
      de: {
        'title': 'Bühnenmaße auswählen'
      },
      en: {
        'title': 'Select platform dimensions'
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
ul {
  list-style-type: none;
  padding: 0;
  width: 60%;
  margin: 0 auto;
}

li:hover {
  color: #0065B7; // blue
}

li {
  display: block;
  margin: 0 10px;
  padding: 8px;
  border: 2px solid transparent;
  color: #101314; // dark gray
  cursor: pointer;
}

li.active {
  font-weight: bold;
  border-color: #0065B7;

  &:hover, &:active, &:focus {
    color: #101314 // dark gray
  }
}
</style>
