<template>
  <div class="base">
    <h1>{{ $t('headline') }}</h1>

    <h3 v-if="activeView === 'home'">{{ $t('subHeadline') }}</h3>

    <button v-on:click="setView('home')" class="pure-button pure-button-primary back-button" v-if="activeView !== 'home'">{{ $t('back') }}</button>

    <div v-if="activeView === 'alfix'">
      <h3 id="alfix-title">{{ $t('alfixTitle') }}</h3>
      <AlfixApp></AlfixApp>
    </div>
    <div v-if="activeView === 'unifix'">
      <h3 id="unifix-title">{{ $t('unifixTitle') }}</h3>
      <UnifixApp></UnifixApp>
    </div>
    <div v-if="activeView === 'mobile'">
      <h3 id="mobile-title">{{ $t('mobileTitle') }}</h3>
      <div v-if="externalMobileConfigurator" class="title">{{ $t('redirected') }}</div>
      <MobileApp v-else></MobileApp>
    </div>
    <div v-if="activeView === 'weather'">
      <h3 id="weather-title">{{ $t('weatherTitle') }}</h3>
      <WeatherProofRoof></WeatherProofRoof>
    </div>

    <div v-if="activeView === 'home'">
      <div class="pure-g" style="justify-content: center;">
        <div class="pure-u-1 pure-u-lg-1-2">
          <div class="pure-g">
            <div class="pure-u-1 pure-u-sm-1-2" v-on:click="setView('alfix')">
              <div class="content">
                <image-on-load v-bind="{ src: images.alfix, imageClass: 'pure-img' }"></image-on-load>
                <div class="title">{{ $t('alfix') }}</div>
              </div>
            </div>
            <div class="pure-u-1 pure-u-sm-1-2" v-on:click="setView('unifix')">
              <div class="content">
                <image-on-load v-bind="{ src: images.unifix, imageClass: 'pure-img' }"></image-on-load>
                <div class="title">{{ $t('unifix') }}</div>
              </div>
            </div>
            <div class="pure-u-1 pure-u-sm-1-2" v-on:click="setView('mobile')">
              <div class="content">
                <image-on-load v-bind="{ src: images.mobile_scaffold, imageClass: 'pure-img' }"></image-on-load>
                <div class="title">{{ $t('mobile') }}</div>
              </div>
            </div>
            <div class="pure-u-1 pure-u-sm-1-2" v-on:click="setView('weather')">
              <div class="content">
                <image-on-load v-bind="{ src: images.weather, imageClass: 'pure-img' }"></image-on-load>
                <div class="title">{{ $t('weather') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UnifixApp from '../../unifix/src/App'
import AlfixApp from '../../alfix/src/App'
import MobileApp from '../../mobile/src/App'
import WeatherProofRoof from '../../weather-proof-roof/src/App'

import ImageOnLoad from '../../common/src/components/ImageOnLoad'
import { numberFormats } from '../../common/src'

import images from './assets/category_*.png'

import Vue from 'vue'
import VueI18n from 'vue-i18n'

import VueJSModal from 'vue-js-modal'

Vue.config.productionTip = false

Vue.use(VueI18n)
Vue.use(VueJSModal, { dynamic: true, injectModalsContainer: true })

const userLocale = window.navigator.language || window.navigator.userLanguage || window.navigator.userLanguage || window.navigator.languages[0] || 'en'
const locationLocale = window.location.href.indexOf('?lang=') > -1 ? window.location.href.split('?lang=').pop() : null
const locale = locationLocale ? locationLocale.substring(0, 2) : userLocale.substring(0, 2)

if (locale === 'en') {
  const descriptionMetaTag = document.querySelector('meta[name="description"]')
  const titleTag = document.querySelector('title')

  if (descriptionMetaTag) {
    descriptionMetaTag.content = 'ALFIX scaffold configurator. Now configure scaffolding or weather protection roof online and have scaffolding components planned automatically. Order directly online!'
  }
  if (titleTag) {
    titleTag.text = 'ALFIX scaffold configurator'
  }
}

const i18n = new VueI18n({
  locale,
  fallbackLocale: 'en',
  messages: {
    de: {
      alfixTitle: 'ALFIX System',
      headline: 'ALFIX Gerüstrechner',
      subHeadline: 'Bitte wählen Sie eines der Gerüstsysteme oder Wetterschutzdach aus, um mit der Berechnung zu beginnen.',
      mobileTitle: 'Fahrgerüst System',
      unifixTitle: 'UNIFIX System',
      unifix: 'UNIFIX Fassadengerüst',
      alfix: 'ALFIX Fassadengerüst',
      mobile: 'ALFIX Fahrgerüste',
      weather: 'Wetterschutzdach VARIO',
      weatherTitle: 'Wetterschutzdach VARIO',
      back: 'zurück zur Konfiguratorauswahl',
      redirected: 'Sie werden in 5 Sekunden zu unserem Konfigurator auf die Seite alfix-fahrgerueste.de weitergeleitet.'
    },
    en: {
      alfixTitle: 'ALFIX System',
      headline: 'ALFIX Scaffold configurator',
      subHeadline: 'Please choose one of our scaffold system or weatherproof roof to start the configuration.',
      mobileTitle: 'ALFIX MOBILE System',
      unifixTitle: 'UNIFIX System',
      unifix: 'UNIFIX Scaffold',
      alfix: 'ALFIX Scaffold',
      mobile: 'ALFIX MOBILE Scaffold',
      weather: 'Weatherproof roof VARIO',
      weatherTitle: 'Weatherproof roof VARIO',
      back: 'back to configurator selection',
      redirected: 'In 5 seconds you will be redirected to our mobile scaffold system coniguration page alfix-fahrgerueste.de.'
    }
  },
  numberFormats
})

export default {
  name: 'App',
  data: function () {
    return {
      activeView: 'home',
      images,
      externalMobileConfigurator: !!window._alfix_mobile_configurator_url,
      redirectTimeout: null
    }
  },
  methods: {
    setView: function (view) {
      this.activeView = view
      window.clearTimeout(this.redirectTimeout)
      if (view === 'mobile' && this.externalMobileConfigurator) {
        this.redirectTimeout = setTimeout(() => {
          window.location.href = window._alfix_mobile_configurator_url
        }, 5000)
      }
    }
  },
  components: {
    UnifixApp,
    AlfixApp,
    MobileApp,
    WeatherProofRoof,
    ImageOnLoad
  },
  i18n
}
</script>

<style lang="scss">
#app {
  margin-top: 30px !important;
}

.base {
  margin-top: 30px;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;

  > h1, > h3 {
    color: #0065B7;
  }
}

div.pure-g {
  div.content {
    cursor: pointer;
    overflow: hidden;
    max-width: 80%;
    margin: 0 auto;
    padding: 5px;
    border: 2px solid transparent;

    > div.title {
      background-color: #FFF000;
      font-weight: bold;
      padding: 10px;
      color: #0065B7;
    }
  }
}

.pure-button.back-button {
  margin-bottom: 20px;
  background-color: #0065B7;
  font-size: 80%;
}

</style>
