<template>
  <div v-bind:style="styles" class="loader"></div>
</template>

<script>

function hex2rgb(hex) {
  return ['0x' + hex[1] + hex[2] | 0, '0x' + hex[3] + hex[4] | 0, '0x' + hex[5] + hex[6] | 0];
}

export default {
  name: 'loading-spinner',
  props: {
    'size': {
      type: Number,
      default: 10
    },
    'margin': {
      type: Number,
      default: 60
    },
    'borderColor': {
      type: String,
      default: '#0065B7'
    }
  },
  computed: {
    styles: function () {
      return {
        'border-top-color': `rgba(${hex2rgb(this.borderColor)}, 0.2)`,
        'border-right-color': `rgba(${hex2rgb(this.borderColor)}, 0.2)`,
        'border-bottom-color': `rgba(${hex2rgb(this.borderColor)}, 0.2)`,
        'border-left-color': this.borderColor,
        'width': `${this.size}em`,
        'height': `${this.size}em`,
        'margin': `${this.margin}px auto`
      }
    }
  }
}

</script>

<style scoped lang="scss">
.loader,
.loader:after {
  border-radius: 50%;
}
.loader {
  border-top: 1.1em solid;
  border-right: 1.1em solid;
  border-bottom: 1.1em solid;
  border-left: 1.1em solid;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: spinning 1.1s infinite linear;
  animation: spinning 1.1s infinite linear;
}
@-webkit-keyframes spinning {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinning {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
