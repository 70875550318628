<template>
  <section>
    <h3>{{ $t('title') }}</h3>

    <div class="pure-g">
      <div class="pure-u-1 pure-u-md-1-2" v-on:click="setArea('OUTSIDE')">
        <div class="content" v-bind:class="{ active: activeArea === 'OUTSIDE' }">
          <image-on-load v-bind="{ src: images['mobile_scaffold_outside_blue'], imageClass: 'pure-img' }"></image-on-load>
          <br>
          {{ translations.outside }}
        </div>
      </div>
      <div class="pure-u-1 pure-u-md-1-2" v-on:click="setArea('INSIDE')">
        <div class="content" v-bind:class="{ active: activeArea === 'INSIDE' }">
          <image-on-load v-bind="{ src: images['mobile_scaffold_inside_blue'], imageClass: 'pure-img' }"></image-on-load>
          <br>
          {{ translations.inside }}
        </div>
      </div>
    </div>

    <footer-next v-on:next="setArea(activeArea, true)" v-bind:label="translations.next"></footer-next>
  </section>
</template>

<script>
import FooterNext from '../../../common/src/components/FooterNext'
import ImageOnLoad from '../../../common/src/components/ImageOnLoad'

import images from '../assets/area/*.png'

export default {
  name: 'step-area',
  props: ['translations', 'activeArea'],
  data: function () {
    return {
      images
    }
  },
  methods: {
    setArea: function (area, emitNextStep) {
      if (area !== this.activeArea) {
        this.$emit('activeAreaChanged', area)
      }
      if (emitNextStep) {
        this.$emit('nextStep')
      }
    }
  },
  components: {
    FooterNext, ImageOnLoad
  },
  i18n: {
    messages: {
      de: {
        'title': 'Einsatzbereich auswählen'
      },
      en: {
        'title': 'Select area of application'
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
section {
  min-width: 60%;
}

div.pure-g {
  color: #101314;

  div.content {
    cursor: pointer;
    overflow: hidden;
    max-width: 80%;
    margin: 0 auto;
    padding: 5px;
    border: 2px solid transparent;

    &.active {
      font-weight: bold;
      border-color: #0065B7;
    }
  }
}
</style>
