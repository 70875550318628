import axios from 'axios'

let calculationData = null
const dataUrl = (window._alfix_data_host ? window._alfix_data_host : 'https://www.alfix-systems.com/shop/app-upload') + '/unifix-articles.json'

export function getUnifixCalculationData () {
  if (calculationData) {
    return Promise.resolve(calculationData)
  }

  return axios.get(dataUrl)
    .then(function (response) {
      calculationData = Object.assign({}, response.data, {
        'MaxLengths': {
          'BAY250': 500,
          'BAY300': 498
        },
        'Bays': {
          'BAY250': 2.50,
          'BAY300': 3.00
        }
      })

      let transformedDecks = {
        'BAY250': {},
        'BAY300': {}
      }

      Object.keys(calculationData.Decks).forEach(function (key) {
        const currentBay = key.indexOf('BAY250') > -1 ? 'BAY250' : 'BAY300'
        let material = key.substr(0, key.indexOf(currentBay))

        transformedDecks[currentBay][material] = {
          ArtNo: calculationData.Decks[key].ArtNo,
          AssemblyFrames: ['STEEL', 'SOLIDWOOD'].indexOf(material) > -1 ? ['UNIFIX100S', 'UNIFIX70S'] : ['UNIFIX70S']
        }
      })
      calculationData.Decks = transformedDecks

      return calculationData
    })
}
