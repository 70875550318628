<template>
  <button
    class="pure-button pure-button-primary"
    v-bind:style="buttonStyleObject"
    v-if="!hasTouch"
    v-on:mouseup="clearInterval()" v-on:mousecancel="clearInterval()" v-on:mousedown="startInterval()"
  >{{ label }}</button>
  <button
    class="pure-button pure-button-primary"
    v-else
    v-on:touchend="clearInterval()" v-on:touchcancel="clearInterval()" v-on:touchstart="startInterval()"
  >{{ label }}</button>
</template>

<script>
export default {
  name: 'on-hold-button',
  props: {
    'label': String,
    'callback': Function,
    'btnColor': {
      type: String,
      default: 'white'
    },
    'btnBackground': {
      type: String,
      default: '#0065B7'
    }
  },
  data: function () {
    return {
      timeout: null,
      interval: null,
      hasTouch: 'ontouchstart' in window,
      buttonStyleObject: {
        color: this.btnColor,
        background: this.btnBackground
      }
    }
  },
  methods: {
    clearInterval: function () {
      if (this.timeout) {
        window.clearTimeout(this.timeout)
        this.timeout = null
      }
      if (this.interval) {
        window.clearInterval(this.interval)
        this.interval = null
      }
    },
    startInterval: function (cb) {
      this.clearInterval();

      this.callback()
      this.timeout = window.setTimeout(() => {
        this.interval = window.setInterval(() => {
          this.callback()
        }, 80)
      }, 400)
    }
  }
}
</script>
