export default {
  de: {
    system: 'Gerüstsystem',
    fieldLength: 'Feldlänge',
    material: 'Belag',
    height: 'Standhöhe',
    length: 'Gerüstlänge',
    materials: {
      STEEL: 'Stahlbelag',
      FRAMEPLATFORM: 'Rahmentafel',
      LIGHTALUMINIUM: 'UNIFX LW Boden',
      SOLIDWOOD: 'Holzbelag'
    }
  },
  en: {
    system: 'Scaffolding system',
    fieldLength: 'Bay length',
    material: 'Scaffolding decks',
    height: 'Standing height',
    length: 'Scaffolding length',
    materials: {
      STEEL: 'Steel deck',
      FRAMEPLATFORM: 'Frame platform',
      LIGHTALUMINIUM: 'UNIFX LW deck',
      SOLIDWOOD: 'Solid wood deck'
    }
  }
}
