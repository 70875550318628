export default {
  de: {
    roofLength: 'Dachlänge',
    span: 'Spannweite'
  },
  en: {
    roofLength: 'Roof length',
    span: 'Span width'
  }
}
