<template>
  <div id="mobile-app">
    <div class="pure-g wrapper">
      <div class="pure-u-11-12 pure-u-md-5-6 pure-u-lg-17-24">
        <step-indicator v-on:stepChanged="handleStepChange" v-bind:steps="steps" v-bind:activeStep="activeStep"></step-indicator>
        <component
          v-if="calculationData"
          v-bind:is="currentView"
          v-bind="currentProperties"
          @activeMeasurementChanged="handleMeasurementChange"
          @activeBuildVariantChanged="handleBuildVariantChange"
          @activeAreaChanged="handleAreaChange"
          @activeWorkingHeightChanged="handleWorkingHeightChange"
          @nextStep="nextStep"
        >
        </component>
        <loading-spinner v-if="!calculationData" v-bind="{size: 6, margin: 60}"></loading-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import { getMobileCalculationData, numberFormats } from '../../common/src'
import LoadingSpinner from '../../common/src/components/LoadingSpinner'
import StepIndicator from '../../common/src/components/StepIndicator'
import { COMPONENTS, STEPS } from './data/steps'

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueJSModal from 'vue-js-modal'

Vue.config.productionTip = false

Vue.use(VueI18n)
Vue.use(VueJSModal, { dynamic: true, injectModalsContainer: true })

const userLocale = window.navigator.language || window.navigator.userLanguage || window.navigator.userLanguage || window.navigator.languages[0] || 'en'
const locationLocale = window.location.href.indexOf('?lang=') > -1 ? window.location.href.split('?lang=').pop() : null

const i18n = new VueI18n({
  locale: locationLocale ? locationLocale.substring(0, 2) : userLocale.substring(0, 2),
  fallbackLocale: 'en',
  numberFormats
})

import translations from './data/translations'

const defaultStep = 0
const defaultMeasurement = 'DECKSIZE_260x060'
const defaultBuildVariant = 0
const defaultArea = 'OUTSIDE'

export default {
  name: 'mobile-app',
  data: function () {
    return {
      calculationData: null,
      activeMeasurement: defaultMeasurement,
      activeStep: defaultStep,
      activeBuildVariant: defaultBuildVariant,
      activeArea: defaultArea,
      activeWorkingHeight: null,
      steps: STEPS.map((step) => step.alias),
      currentView: STEPS[defaultStep].component
    }
  },
  mounted: function () {
    getMobileCalculationData().then(calcData => this.calculationData = calcData)
  },
  methods: {
    handleMeasurementChange: function (measurement) {
      this.activeMeasurement = measurement
    },
    handleBuildVariantChange: function (buildVariant) {
      this.activeBuildVariant = buildVariant
    },
    handleAreaChange: function (area) {
      this.activeArea = area
    },
    handleWorkingHeightChange: function (workingHeight) {
      this.activeWorkingHeight = workingHeight
    },
    handleStepChange: function (step) {
      if (step < 3) {
        this.activeWorkingHeight = null
      }
      if (step < 2) {
        this.activeArea = defaultArea
      }
      if (step < 1) {
        this.activeBuildVariant = defaultBuildVariant
      }
      this.activeStep = step
      this.currentView = STEPS[step].component
    },
    nextStep: function () {
      this.handleStepChange(this.activeStep + 1)
    }
  },
  computed: {
    currentProperties: function () {
      if (!this.currentView) {
        return null;
      }
      if (this.currentView.name === 'step-calculation-results') {
        return { translations: translations[this.$i18n.locale], activeBuildVariant: this.activeBuildVariant, activeMeasurement: this.activeMeasurement, activeArea: this.activeArea, activeWorkingHeight: this.activeWorkingHeight, calculationData: this.calculationData }
      } else if (this.currentView.name === 'step-working-heights') {
        return { translations: translations[this.$i18n.locale], activeBuildVariant: this.activeBuildVariant, activeMeasurement: this.activeMeasurement, activeArea: this.activeArea, activeWorkingHeight: this.activeWorkingHeight, calculationData: this.calculationData }
      } else if (this.currentView.name === 'step-area') {
        return { translations: translations[this.$i18n.locale], activeBuildVariant: this.activeBuildVariant, activeMeasurement: this.activeMeasurement, activeArea: this.activeArea }
      } else if (this.currentView.name === 'step-variants') {
        return { translations: translations[this.$i18n.locale], activeBuildVariant: this.activeBuildVariant, activeMeasurement: this.activeMeasurement, calculationData: this.calculationData }
      } else if (this.currentView.name === 'step-ramp-measurement') {
        return { translations: translations[this.$i18n.locale], activeMeasurement: this.activeMeasurement, calculationData: this.calculationData }
      } else {
        return {translations: translations[this.$i18n.locale]}
      }
    }
  },
  components: {
    StepIndicator,
    LoadingSpinner,
    ...COMPONENTS
  },
  i18n
}
</script>

<style lang="scss">
#mobile-app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 60px;
  color: #101314;
  font-size: 1.2rem;

  > div.wrapper {
    justify-content: center;
  }
}

.loading-wrapper {
  width: 300px;
  height: 300px;
  position: relative;
  overflow: hidden;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.v--modal-box.v--modal {
  margin-top: 20px;
  margin-bottom: 20px !important;
}
</style>
