<template>
  <div>
    <h3>{{ this.translations.system }}</h3>
    <section>
      <ul>
        <li
          v-on:click="setSystem(system)"
          v-for="(system) of systems"
          v-bind:key="system"
          v-bind:class="{ active: (activeSystem === system) }"
        >
          {{ system }}
        </li>
      </ul>
    </section>

    <h3>{{ this.translations.fieldLength }}</h3>
    <section>
      <ul>
        <li
          v-on:click="setFieldLength(key)"
          v-for="(length, key) of fieldLengths"
          v-bind:key="key"
          v-bind:class="{ active: (activeFieldLength === key) }"
        >
          {{ $n(length, 'measurement') }} m
        </li>
      </ul>
    </section>

    <h3>{{ this.translations.material }}</h3>
    <section>
      <ul>
        <li
          v-on:click="isValid(materialName) && setMaterial(materialName)"
          v-for="(materialName, index) in materialNames"
          v-bind:key="index"
          v-bind:class="{ active: (activeMaterial === materialName), disabled: !isValid(materialName) }"
        >
          {{ translations.materials[materialName] }}
        </li>
      </ul>
    </section>

    <h3>{{ this.translations.height }}</h3>
    <section>
      <div class="pure-button-group">
        <on-hold-button v-bind:callback="decreaseHeight" label="-"></on-hold-button>
        <button class="pure-button number"><strong>{{ $n(activeHeight, 'measurement') }}</strong> m</button>
        <on-hold-button v-bind:callback="increaseHeight" label="+"></on-hold-button>
      </div>
      <small>(min. <strong>{{ $n(2, 'measurement') }} m</strong>, max <strong>{{ $n(24, 'measurement') }} m</strong>)</small>
    </section>

    <h3>{{ this.translations.length }}</h3>
    <section>
      <small>{{ $t('equalTo') }} <strong>{{ fields }}</strong> {{ $tc('bay', fields) }}</small>
      <div class="pure-button-group margin-top">
        <on-hold-button v-bind:callback="decreaseLength" label="-"></on-hold-button>
        <button class="pure-button number">
          <strong>{{ $n(activeLength, 'measurement') }}</strong> m<br>
        </button>
        <on-hold-button v-bind:callback="increaseLength" label="+"></on-hold-button>
      </div>
      <small>(min. <strong>{{ $n(this.baseFieldLength, 'measurement') }}</strong> m, max <strong>{{ $n(this.maxLength, 'measurement') }}</strong> m)</small>
    </section>

    <h3>{{ $t('accessLadders') }}</h3>
    <section>
      <div class="pure-button-group">
        <on-hold-button v-if="(maxStairCount !== minStairCount)" v-bind:callback="decreaseStairCount" label="-"></on-hold-button>
        <button class="pure-button number"><strong>{{ $n(activeStairCount) }}</strong></button>
        <on-hold-button v-if="(maxStairCount !== minStairCount)" v-bind:callback="increaseStairCount" label="+"></on-hold-button>
      </div>
      <small>(min. <strong>{{ minStairCount }}</strong>, max. <strong>{{ maxStairCount }}</strong>)</small>
    </section>

    <footer-next v-bind:label="$t('calculate')" v-on:next="setData()"></footer-next>
  </div>
</template>

<script>
import FooterNext from './FooterNext'
import OnHoldButton from './OnHoldButton'
import { createCalculation } from '../data/calculation'

export default {
  name: 'step-calculation-form',
  props: {
    'translations': Object,
    'system': String,
    'fieldLength': String,
    'material': String,
    'height': Number,
    'length': Number,
    'stairCount': Number,
    'calculationData': Object
  },
  data: function () {
    return {
      activeSystem: this.system,
      activeFieldLength: this.fieldLength,
      activeMaterial: this.material,
      activeHeight: this.height,
      activeLength: this.length,
      activeStairCount: this.stairCount,
      systems: Object.keys(this.calculationData.AssemblyFrame),
      fieldLengths: this.calculationData.Bays,
      calculation: createCalculation(this.calculationData),
    }
  },
  computed: {
    materialNames: function () {
      return Object.keys(this.calculationData.Decks[this.fieldLength]);
    },
    minStairCount: function () {
      return Math.ceil(this.activeLength / 50)
    },
    maxStairCount: function () {
      return this.fields;
    },
    baseFieldLength: function () {
      return this.fieldLengths[this.activeFieldLength]
    },
    maxLength: function () {
      return this.calculationData.MaxLengths[this.activeFieldLength]
    },
    fields: function () {
      return this.calculation.fields(this.activeFieldLength, this.activeLength)
    }
  },
  methods: {
    getLiStyle: function (active, disabled) {
      let color = this.hoverState ? this.colors.blue : this.colors.darkGrey
      if (active) {
        this.colors.blue
      }
      if (disabled) {
        color = this.colors.lightGrey
      }
      return {
        'color': color,
        'border-color': active ? this.colors.blue: ''
      }
    },
    decreaseStairCount: function () {
      this.activeStairCount = this.activeStairCount <= this.minStairCount ? this.maxStairCount : this.activeStairCount - 1
    },
    increaseStairCount: function () {
      this.activeStairCount = this.activeStairCount >= this.maxStairCount ? this.minStairCount : this.activeStairCount + 1
    },
    decreaseHeight: function () {
      this.activeHeight = this.activeHeight === 2 ? 24 : this.activeHeight - 2
    },
    increaseHeight: function () {
      this.activeHeight = this.activeHeight === 24 ? 2 : this.activeHeight + 2
    },
    updateStairCount: function () {
      if (this.activeStairCount > this.maxStairCount) {
        this.activeStairCount = this.maxStairCount
      }
      if (this.activeStairCount < this.minStairCount) {
        this.activeStairCount = this.minStairCount
      }
    },
    decreaseLength: function () {
      this.activeLength = this.activeLength === this.baseFieldLength ? this.maxLength : parseFloat((this.activeLength - this.baseFieldLength).toFixed(2))
      this.updateStairCount()
    },
    increaseLength: function () {
      this.activeLength = this.activeLength === this.maxLength ? this.baseFieldLength : parseFloat((this.activeLength + this.baseFieldLength).toFixed(2))
      this.updateStairCount()
    },
    isValid: function (materialName) {
      return this.calculationData.Decks[this.activeFieldLength][materialName].AssemblyFrames.indexOf(this.activeSystem) > -1
    },
    setMaterial: function (materialName) {
      if (this.isValid(materialName)) {
        this.activeMaterial = materialName
      }
    },
    setSystem: function (system) {
      if (system === this.activeSystem) {
        return null
      }

      this.activeSystem = system

      if (!this.isValid(this.activeMaterial)) {
        this.activeMaterial = this.materialNames[0]
      }
    },
    setFieldLength: function (fieldLength) {
      const length = this.fieldLengths[fieldLength]

      if (fieldLength === this.activeFieldLength) {
        return null;
      }

      this.activeLength = parseFloat((Math.floor(this.activeLength / length) * length).toFixed(2)) || length;
      this.activeFieldLength = fieldLength
    },
    setData: function () {
      this.$emit('dataChanged', {
        system: this.activeSystem,
        fieldLength: this.activeFieldLength,
        height: this.activeHeight,
        length: this.activeLength,
        stairCount: this.activeStairCount,
        material: this.activeMaterial
      })
      this.$emit('nextStep')
    },
    updateHover: function (isHover) {
      this.hoverState = isHover
    }
  },
  i18n: {
    messages: {
      de: {
        'calculate': 'Berechnen',
        'accessLadders': 'Reihen von Leitergängen',
        'equalTo': 'entspricht',
        'bay': 'Feld | Felder'
      },
      en: {
        'calculate': 'Calculate',
        'accessLadders': 'Access ladder rows',
        'equalTo': 'equal to',
        'bay': 'bay | bays'
      }
    }
  },
  components: {
    FooterNext,
    OnHoldButton
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
p {
  padding: 5px;
}

ul {
  list-style-type: none;
  padding: 0;
  width: 60%;
  margin: 0 auto;
}

@media screen and (max-width: 35.5em) {
  ul {
    width: 98%;
  }
}

li {
  display: block;
  margin: 0 10px;
  padding: 8px;
  border: 2px solid transparent;
  color: #101314; // dark gray
  cursor: pointer;
}

li:hover {
  color: #0065B7; // blue
}

li.active {
  font-weight: bold;
  border-color: #0065B7;

  &:hover, &:active, &:focus {
    color: #101314 // dark gray
  }
}

button {
  border: 1px solid transparent;
}

button.pure-button.number {
  min-width: 125px;
  background: #fff;
  cursor: default;
  color: #0065B7;
  border: 1px solid #0065B7;

  &:hover {
    background: #fff;
    box-shadow: 0 0 0 0 transparent;
  }

  &:active {
    background: #fff;
    box-shadow: 0;
  }
}

section {
  margin-bottom: 40px;

  &:last-of-type {
    margin-bottom: 20px;
  }
}

.pure-button-group {
  margin-bottom: 10px;

  &.margin-top {
    margin-top: 10px;
  }
}
</style>
