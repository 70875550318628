export default {
  'en': {
    'DECKSIZE_260x060': '2.60 x 0.60 m',
    'DECKSIZE_260x140': '2.60 x 1.40 m',
    'DECKSIZE_180x060': '1.80 x 0.60 m',
    'DECKSIZE_180x140': '1.80 x 1.40 m',
    'CENTRAL_NO_STABILISER': 'Central, without stabiliser',
    'LATERAL_NO_STABILISER': 'Lateral, without stabiliser',
    'WITH_WALL_SUPPORT': 'With wall support',
    'LATERIAL_WITH_STABILISER': 'Lateral, with 2 stabilisers',
    'CENTRAL_WITH_STABILISER': 'Central, with 4 stabiliser',
    'WITHOUT_ROLLING_BEAM': 'Withou rolling beam',
    'inside': 'Indoors',
    'outside': 'Outdoors',
    'next': 'Apply & Continue'
  },
  'de': {
    'DECKSIZE_260x060': '2,60 x 0,60 m',
    'DECKSIZE_260x140': '2,60 x 1,40 m',
    'DECKSIZE_180x060': '1,80 x 0,60 m',
    'DECKSIZE_180x140': '1,80 x 1,40 m',
    'CENTRAL_NO_STABILISER': 'Mittig ohne Ausleger',
    'LATERAL_NO_STABILISER': 'Seitlich ohne Ausleger',
    'WITH_WALL_SUPPORT': 'Mit Wandabstützung',
    'LATERIAL_WITH_STABILISER': 'Seitlich mit 2 Auslegern',
    'CENTRAL_WITH_STABILISER': 'Mittig mit 4 Auslegern',
    'WITHOUT_ROLLING_BEAM': 'Ohne Fahrbalken',
    'inside': 'Im Gebäude',
    'outside': 'Im Freien',
    'next': 'Übernehmen & Fortfahren'
  }
}
