<template>
  <div style="padding: 20px;">
    <div slot="top-right">
      <div class="pure-g">
        <div class="pure-u-2-3">
          <h3>{{ $t('requestOffer') }}</h3>
        </div>
        <div class="pure-u-1-3">
          <div style="display: flex; justify-content: flex-end">
            <button class="pure-button" v-on:click="$emit('close')">
              {{ $t('close') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <form v-if="!loading" class="pure-form pure-form-stacked" style="justify-content: center;" v-on:submit.prevent.stop="save">
        <fieldset class="pure-group">
          <div class="pure-control-group">
            <label for="type">{{ $t('offerType') }}</label>
            <select v-model="contactForm.offerType" id="type" name="type">
              <option value="Miete">{{ $t('rent') }}</option>
              <option value="Kauf">{{ $t('buy') }}</option>
            </select>
          </div>
          <div class="pure-control-group">
            <label for="count">{{ $t('count') }} *</label>
            <input v-model="contactForm.count" size="40" id="count" required type="number" value="1" min="1" v-bind:placeholder="$t('count')">
          </div>
        </fieldset>

        <fieldset class="pure-group">
          <div class="pure-control-group">
            <label for="salutation">{{ $t('salutation') }}</label>
            <select v-model="contactForm.salutation" id="salutation" name="salutation">
              <option value="">{{ $t('pleaseChoose') }}</option>
              <option value="Herr">{{ $t('sir') }}</option>
              <option value="Frau">{{ $t('madam') }}</option>
            </select>
          </div>

          <div class="pure-control-group">
            <label for="firstname">{{ $t('firstname') }}</label>
            <input v-model="contactForm.firstname" size="40" id="firstname" name="firstname" type="text" v-bind:placeholder="$t('firstname')">
          </div>

          <div class="pure-control-group">
            <label for="lastname">{{ $t('lastname') }}</label>
            <input v-model="contactForm.lastname" size="40" id="lastname" type="text" v-bind:placeholder="$t('lastname')">
          </div>
        </fieldset>

        <fieldset class="pure-group">
          <div class="pure-control-group">
            <label for="company">{{ $t('company') }} *</label>
            <input v-model="contactForm.company" size="40" id="company" required type="text" v-bind:placeholder="$t('company')">
          </div>

          <div class="pure-control-group">
            <label for="street">{{ $t('streetNumber') }} *</label>
            <input v-model="contactForm.streetNumber" size="40" id="street" required type="text" v-bind:placeholder="$t('streetNumber')">
          </div>
          <div class="pure-control-group">
            <label for="zip">{{ $t('zip') }} *</label>
            <input v-model="contactForm.zip" size="40" id="zip" required type="text" v-bind:placeholder="$t('zip')">
          </div>
          <div class="pure-control-group">
            <label for="location">{{ $t('location') }} *</label>
            <input v-model="contactForm.location" size="40" id="location" required type="text" v-bind:placeholder="$t('location')">
          </div>
          <div class="pure-control-group">
            <label for="country">{{ $t('country') }}</label>
            <select v-model="contactForm.country" name="country" required>
              <option value="Deutschland">{{ $t('germany') }}</option>
              <option value="England">{{ $t('england') }}</option>
              <option value="Finnland">{{ $t('finland') }}</option>
              <option value="Frankreich">{{ $t('france') }}</option>
              <option value="Italien">{{ $t('italy') }}</option>
              <option value="Österreich">{{ $t('austria') }}</option>
              <option value="Russland">{{ $t('russia') }}</option>
              <option value="Spanien">{{ $t('spain') }}</option>
            </select>
          </div>
        </fieldset>

        <fieldset class="pure-group">
          <div class="pure-control-group">
            <label for="phone">{{ $t('phone') }} *</label>
            <input v-model="contactForm.phone" size="40" id="phone" required type="tel" v-bind:placeholder="$t('phone')">
          </div>
          <div class="pure-control-group">
            <label for="mail">{{ $t('mail') }} *</label>
            <input v-model="contactForm.mail" size="40" id="mail" required type="email" v-bind:placeholder="$t('mail')">
          </div>
        </fieldset>

        <fieldset class="pure-group">
          <div class="pure-control-group">
            <span style="color: red;"> * </span>
            <small>{{ $t('tos1') }} <a href="https://www.alfix-fahrgerueste.de/datenschutzerklaerung/">{{ $t('dataPrivacyStatement') }}</a> {{ $t('tos2') }}</small>
          </div>
        </fieldset>

        <div class="pure-control-group">
          <div class="pure-u-1" style="display: flex; justify-content: center;">
            <button type="button" v-on:click="save()" class="pure-button pure-button-primary" v-bind:disabled="!isValid(contactForm)">{{ $t('send') }}</button>
          </div>
        </div>
      </form>
      <loading-spinner v-if="loading"></loading-spinner>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import LoadingSpinner from './LoadingSpinner'
const apiUrl = window._alfix_offer_host ? window._alfix_offer_host : 'http://localhost:9000/api.php'

export default {
  name: 'offer-modal',
  props: ['contactForm', 'scaffoldType', 'calcBase', 'articles'],
  data: function () {
    return {
      loading: false
    }
  },
  methods: {
    save: function () {
      if (this.isValid(this.contactForm) && this.scaffoldType && this.articles && this.calcBase) {
        this.loading = true

        return axios.post(apiUrl, {
            scaffoldType: this.scaffoldType,
            offerType: this.contactForm.offerType,
            count: this.contactForm.count,
            lastname: this.contactForm.lastname,
            firstname: this.contactForm.firstname,
            salutation: this.contactForm.salutation,
            streetNumber: this.contactForm.streetNumber,
            country: this.contactForm.country,
            zip: this.contactForm.zip,
            company: this.contactForm.company,
            location: this.contactForm.location,
            phone: this.contactForm.phone,
            mail: this.contactForm.mail,
            calcBase: this.calcBase,
            articles: this.articles
          })
          .then(() => {
            this.$emit('close')
            this.loading = false
          }, () => {
            this.$emit('error')
            this.loading = false
          })
      }
    },
    isValid: function (contactForm) {
      return contactForm.country && contactForm.zip && contactForm.streetNumber && contactForm.count && contactForm.phone && contactForm.company && contactForm.location && contactForm.mail
    }
  },
  i18n: {
    messages: {
      de: {
        requestOffer: 'Angebot anfordern',
        offerType: 'Angebotsart',
        buy: 'Kauf',
        rent: 'Miete',
        salutation: 'Anrede',
        pleaseChoose: 'Bitte auswählen',
        sir: 'Herr',
        madam: 'Frau',
        firstname: 'Vorname',
        lastname: 'Nachname',
        company: 'Firma',
        zip: 'Postleitzahl',
        streetNumber: 'Straße / Hausnummer',
        location: 'Ort',
        country: 'Land',
        germany: 'Deutschland',
        england: 'England',
        austria: 'Österreich',
        finland: 'Finnland',
        france: 'Frankreich',
        italy: 'Italien',
        russia: 'Russland',
        spain: 'Spanien',
        count: 'Anzahl',
        mail: 'E-Mail',
        phone: 'Telefon',
        requestOffer: 'Angebot anfordern',
        close: 'Schließen',
        send: 'Abschicken',
        tos1: 'Einwilligungserklärung: Durch die Eingabe nachfolgend abgefragter Daten und Ihrer Nachricht willigen Sie in die Erhebung und Verwendung personenbezogener Daten zu den in den',
        tos2: 'dargestellten Zwecken ein. Sie können Ihre Einwilligung jederzeit widerrufen!',
        dataPrivacyStatement: 'Datenschutzhinweisen'
      },
      en: {
        requestOffer: 'Request an offer',
        offerType: 'Type of Offer',
        buy: 'Buy',
        rent: 'Rent',
        salutation: 'Salutation',
        pleaseChoose: 'please choose',
        sir: 'Sir',
        madam: 'Ms/Mrs',
        firstname: 'Firstname',
        lastname: 'Lastname',
        company: 'Company',
        zip: 'Zip code',
        streetNumber: 'Street / House number',
        location: 'Location',
        country: 'Country',
        germany: 'Germany',
        england: 'England',
        austria: 'Austria',
        finland: 'Finland',
        france: 'France',
        italy: 'Italy',
        russia: 'Russia',
        spain: 'Spain',
        count: 'Quantity',
        mail: 'E-Mail',
        phone: 'Phone',
        requestOffer: 'Request offer',
        close: 'Close',
        send: 'Send',
        tos1: 'Informed Consent: With entering and sending your information you agree with our',
        tos2: '. It is possible to revoke your agreement anytime!',
        dataPrivacyStatement: 'data privacy statement'
      }
    }
  },
  components: {
    LoadingSpinner
  }
}
</script>
