import CalculationForm from '../../../common/src/components/CalculationForm'
import CalculationResults from '../components/CalculationResults'

export const STEPS = [{
  alias: 'CalculationForm',
  component: CalculationForm
}, {
  alias: 'CalculationResults',
  component: CalculationResults
}]

export const COMPONENTS = {
  'CalculationForm': CalculationForm,
  'CalculatingResults': CalculationResults
}
