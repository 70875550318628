export const numberFormats = {
  'en': {
    measurement: {
      maximumFractionDigits: 2, minimumFractionDigits: 2
    },
    currency: {
      style: 'currency', currency: 'EUR', currencyDisplay: 'symbol'
    }
  },
  'de': {
    currency: {
      style: 'currency', currency: 'EUR', currencyDisplay: 'symbol'
    },
    measurement: {
      maximumFractionDigits: 2, minimumFractionDigits: 2
    }
  }
}
