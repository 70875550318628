const dataUrl = (window._alfix_cart_url ? window._alfix_cart_url : 'https://shop.alfix.de/shop/shopware/mobileAddToCart/add')

export function addToCart (articles, language) {
  if (!articles || !articles.length) {
    return false
  }

  const orderData = {
    items: articles.map(function (article) {
      return {
        article_number: article.article_number,
        qty: article.quantity
      }
    }),
    store: language || 'de'
  }

  const formEl = document.createElement('FORM')
  formEl.action = dataUrl
  formEl.method = 'POST'

  const orderInputEl = document.createElement('INPUT')
  orderInputEl.name = 'order'
  orderInputEl.value = JSON.stringify(orderData)

  formEl.appendChild(orderInputEl)

  formEl.style.display = 'none'

  document.body.appendChild(formEl)
  formEl.submit()

  formEl.remove()

  return true
}
