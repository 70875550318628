<template>
  <section>
    <div class="pure-g">
      <div v-bind:class="['nav-item', `pure-u-1-${steps.length}`, {active: activeStep === index, clickable: index <= activeStep}]" v-on:click.stop="setActiveStep(index)" v-bind:key="index" v-for="(step, index) in steps">
        <div class="round" v-bind:style="styles(index)">{{ index + 1 }}</div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'step-indicator',
  props: {
    'activeStep': {
      type: Number,
      default: 0
    },
    'steps': Array,
    'activeBackgroundColor': {
      type: String,
      default: '#FFF000'
    },
    'backgroundColor': {
      type: String,
      default: '#0065B7'
    },
    'borderColor': {
      type: String,
      default: '#0065B7'
    },
    'activeColor': {
      type: String,
      default: '#0065B7'
    },
    'color': {
      type: String,
      default: 'white'
    }
  },
  data: function () {
    return {
      style: {

      }
    }
  },
  methods: {
    styles: function (index) {
      return {
        'background-color': index === this.activeStep ? this.activeBackgroundColor : this.backgroundColor,
        border: `2px solid ${this.borderColor}`,
        color: index === this.activeStep ? this.activeColor : this.color
      }
    },
    setActiveStep: function (index) {
      if (index < this.activeStep) {
        this.$emit('stepChanged', index)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
section {
  margin-bottom: 30px;
}

div.nav-item {
  color: #fff;
  opacity: .66;

  .round {
    border-radius: 50%;
    padding: 3px;
    height: 30px;
    width: 30px;
    margin: 0 auto;
    line-height: 30px;
    text-align: center;
  }

  &.clickable {
    opacity: 1;
    color: #fff;
    cursor: pointer;
  }

  &.active {
    opacity: 1;
  }
}
</style>
